<template>
  <div>
    <v-list
      v-if="appointments && appointments.length > 0"
      class="text-left mb-3"
    >
      <v-list-item
        v-for="appointment in appointments"
        :key="appointment.id"
        class="border-bottom-grey mx-5"
      >
        <v-list-item-icon class="align-self-center">
          <icon-status
            :status="appointment.status"
            :text="appointment.formattedStatus"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="body-2">
            {{ appointment.therapistName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ appointment.startAt | formatDateTime }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="mx-5" @click="redirectToAppointments">
        <v-list-item-title
          class="secondary--text font-weight-bold text-decoration-underline body-2"
        >
          Ver todos os agendamentos
        </v-list-item-title>
        <v-list-item-action class="align-self-center">
          <v-icon color="secondary">arrow_forward</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <empty-list v-else text="Nenhum agendamento para mostrar" />
  </div>
</template>

<script>
export default {
  name: "NextAppointmentsPatient",
  components: {
    IconStatus: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/IconStatus"
      ),
  },
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    redirectToAppointments() {
      this.$router.push({ name: "listAppointmentsPatient" });
    },
  },
};
</script>

<style scoped></style>
